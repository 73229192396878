/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "react-flexbox-grid/dist/react-flexbox-grid.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "typeface-abril-fatface"
import "./src/styles/global.css"
